import Head from 'next/head';
import isArray from 'lodash/isArray';

type TitleProps = {
  title: string | string[];
};

function Title({ title }: TitleProps) {
  const pageTitle = isArray(title) ? title.join(' | ') : title;
  return (
    <Head>
      <title>{`${pageTitle} | Mockingjay`}</title>
    </Head>
  );
}

export default Title;
