import { useRouter } from 'next/router';
import { useEffect } from 'react';
import logger from '@/utils/logger';
import Title from '@/components/bricks/Title/Title';

function Home() {
  const router = useRouter();
  useEffect(() => {
    router.replace('/tests').catch((e) => logger.error(e));
  });
  return <Title title="Tests" />;
}

export default Home;
